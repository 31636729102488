import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RightOutlined = _resolveComponent("RightOutlined")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_tabs, {
      class: "route-tabs",
      activeKey: _ctx.activeKey,
      "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
      onTabClick: _ctx.tabClick
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subRoutes, (route, index) => {
          return (_openBlock(), _createBlock(_component_a_tab_pane, { key: index }, {
            tab: _withCtx(() => [
              _createElementVNode("span", null, [
                _createTextVNode(_toDisplayString(route.meta.title) + " ", 1),
                _renderSlot(_ctx.$slots, "default", {
                  routername: route.name
                }, undefined, true)
              ]),
              (index + 1 !== _ctx.subRoutes.length)
                ? (_openBlock(), _createBlock(_component_RightOutlined, {
                    key: 0,
                    class: "arrow"
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 3
    }, 8, ["activeKey", "onTabClick"]),
    (_ctx.showRouterView)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}