
import { defineComponent, ref } from "vue"
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
import type { RouteLocationNormalized } from 'vue-router'
import { useI18n } from "vue-i18n"
import RouteTabs from '@/views/Checking/spotChecking/components/RouteTabs.vue'

export default defineComponent({
  name: 'pendingApproval',
  components: {
    RouteTabs
  },
  setup() {
    const { t } = useI18n()
    type BreadCrumbData = { name: string; path: string }
    const breadCrumbData = ref<BreadCrumbData[]>([])

    const route = useRoute()

    const changeBreadCrumb = (breadCrumb: BreadCrumbData) => {
      const index = breadCrumbData.value.findIndex(item => item.name === breadCrumb.name)
      if (index === -1) {
        breadCrumbData.value[breadCrumbData.value.length - 1].path = route.path
        breadCrumbData.value.push(breadCrumb)
      } else {
        breadCrumbData.value.splice(index + 1)
        breadCrumbData.value[breadCrumbData.value.length - 1].path = ''
      }
    }
    
    const setBreadCrumbData = (route: RouteLocationNormalized) => {
      switch (route.name) {
        case 'pendingApprovalPayoutRound':
          changeBreadCrumb({name: t('checking.programDetail'), path: ''})
          break;
        case 'pendingApprovalPayoutDetail':
          changeBreadCrumb({name: t('checking.vinDetail'), path: ''})
          break;
        case 'approvalReview':
          breadCrumbData.value = [
            {name: t('checking.approvalReview'), path: ''}
          ]
          break;
        default:
          breadCrumbData.value = [
            {name: t('checking.spotCheckingPendingApproval'), path: ''}
          ]
          break;
      }
    }

    setBreadCrumbData(route)
    onBeforeRouteUpdate(setBreadCrumbData)

    return {
      breadCrumbData
    }
  }
})
